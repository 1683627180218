.header {
    width: 100%;
    height: 2rem;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    text-transform: uppercase;
    justify-content: space-between;
    
    p {
        font-size: 12px;
        font-family: "monda";
        color: white;
        letter-spacing: 2px;

    }
}