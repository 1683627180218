@font-face {
    font-family: "monda";
    src: url("../fonts/Monda-Regular.ttf");
}

@font-face {
    font-family: "monda-bold";
    src: url("../fonts/Monda-Bold.ttf");
}

@font-face {
    font-family: "bungee";
    src: url("../fonts/BungeeHairline-Regular.ttf");
}

@font-face {
    font-family: "slackside";
    src: url("../fonts/SlacksideOne-Regular.ttf");
}