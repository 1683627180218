.logo{

    &__positioner {
        z-index: 2;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 90px;
        position: relative;
        top: 19px;
    }
    
    &__drone {
        background-image: url("../../../public/DRONE.png");
        width: 160px;
        height: 59px;
        background-position: bottom;
        background-size: cover;
        background-position-y: 3px;
        position: relative;
        z-index: 1;
        margin: auto;
    }
    
    &__box {
        display: flex;
        flex-direction: column;
        border: 2px solid black;
        background-color: white;
        width: 200px;
        padding: 0.5rem 0rem;
    }

    &__top-text {
        font-family: "bungee";
        font-size: 32px;
        margin: 0;
        font-weight: bold;
        line-height: 32px;
    }

    &__bottom-text {
        font-family: "slackside";
        font-size: 32px;
        margin: 0;
        line-height: 32px;
        text-transform: uppercase;
    }
}